import Video1 from "../video/RAKtherm-Concealed-Cistern.mp4";
import Video2 from "../video/RAKtherm-Ultimate-Piping-Solutions.mp4";
import Video3 from "../video/RAKtherm-Globals-Response-on-Covid-19.mp4";
import Video4 from "../video/The-New-Generation-Push-Fit-System.mp4";
import Video5 from "../video/NOW-5-Layers-Reinforced-Pipes.mp4";
import Video6 from "../video/RAKtherm-Unveils-its-RNP-Technology.mp4";
import Video7 from "../video/Engineered-DWV-Piping-Systems.mp4";
import Video8 from "../video/Longevous-Sealing-Technology-from-RAKtherm.mp4";
import Video9 from "../video/RAKtherm-Globals--Circle-of-Integrity.mp4";
import Video10 from "../video/Top-5-Reasons-Why-You-Need-to-Visit-RAKtherm-at-The-Big5-Dubai.mp4";
import Video11 from "../video/RAKtherm-at-The-Big5-Dubai.mp4";
import Video12 from "../video/Concealed-Ball-Valve.mp4";
import Video13 from "../video/Y-Globe-Valve.mp4";
import Video14 from "../video/Y-Type-Strainer.mp4";
import Video15 from "../video/RAKtherm-Ultimate-Sealant-Solutions.mp4";
import Video16 from "../video/RAKtherm's-SPRING-and-SWING-CHECK-VALVE.mp4";
import Video17 from "../video/RAKtherm-Decalcifer.mp4";

export const VideoData = [
  {
    id: 1,
    src: `${Video1}`,
    title: "RAKtherm Concealed Cistern",
  },
  {
    id: 2,
    src: `${Video2}`,
    title: "RAKtherm Ultimate Piping Solutions",
  },
  {
    id: 3,
    src: `${Video3}`,
    title: "RAKtherm Global's Response on Covid-19",
  },
  {
    id: 4,
    src: `${Video4}`,
    title: "The New Generation Push-Fit System of Raktherm",
  },
  {
    id: 5,
    src: `${Video5}`,
    title: "NOW 5 Layers Reinforced Pipes!",
  },
  {
    id: 6,
    src: `${Video6}`,
    title: "RAKtherm Unveils its RNP Technology",
  },
  {
    id: 7,
    src: `${Video7}`,
    title: "Engineered DWV Piping Systems",
  },
  {
    id: 8,
    src: `${Video8}`,
    title: "Longevous Sealing Technology from RAKtherm",
  },
  {
    id: 9,
    src: `${Video9}`,
    title: "RAKtherm Global's Circle of Integrity",
  },
  {
    id: 10,
    src: `${Video10}`,
    title: "Top 5 Reasons Why You Need to Visit RAKtherm at The Big5 Dubai",
  },
  {
    id: 11,
    src: `${Video11}`,
    title: "RAKtherm at The Big5 Dubai",
  },
  {
    id: 12,
    src: `${Video12}`,
    title: "Concealed Ball Valve",
  },
  {
    id: 13,
    src: `${Video13}`,
    title: "RAKtherm Concealed Cistern",
  },
  {
    id: 14,
    src: `${Video14}`,
    title: "Y Type Strainer",
  },
  {
    id: 15,
    src: `${Video15}`,
    title: "RAKtherm Ultimate Sealant Solutions",
  },
  {
    id: 16,
    src: `${Video16}`,
    title: "RAKtherm’s SPRING and SWING CHECK VALVE",
  },
  {
    id: 17,
    src: `${Video17}`,
    title: "RAKtherm Decalcifer",
  },
];
