export const MEGA_PROJECT = [
    {
        id: "1",
        title: "ABU DHABI MALL",
        src: "https://www.raktherm.com/upload/spic/spic_2abu%20dhabi%20mall1.jpg",
        link: ""
    },
    {
        id: "2",
        title: "CARREFOUR - FUJAIRAH",
        src: "https://www.raktherm.com/upload/spic/spic_3care44.jpg",
        link: ""
    },
    {
        id: "3",
        title: "ABBCO TOWER - SHARJAH",
        src: "https://www.raktherm.com/upload/spic/spic_4abbaco%20tower1.jpg",
        link: ""
    },
    {
        id: "4",
        title: "AJMAN CORNICHE RESIDENCE",
        src: "https://www.raktherm.com/upload/spic/spic_5ajman%20corniche%20residence1.jpg",
        link: ""
    },
    {
        id: "5",
        title: "AL ANWAR TOWER - AJMAN",
        src: "https://www.raktherm.com/upload/spic/spic_6al%20anwar%20aj1.jpg",
        link: ""
    },
    {
        id: "6",
        title: "AL DAR PROPERTIES - ABU DHABI",
        src: "https://www.raktherm.com/upload/spic/spic_7al%20dar%20properties1.jpg",
        link: ""
    },
    {
        id: "7",
        title: "AL HAMRAH VILLAGE - RAK",
        src: "https://www.raktherm.com/upload/spic/spic_8al%20hamrah1.jpg",
        link: ""
    },
    {
        id: "8",
        title: "AL KHAN - SHARJAH",
        src: "https://www.raktherm.com/upload/spic/spic_9Al%20Khan%20Sharja1.jpg",
        link: ""
    },
    {
        id: "9",
        title: "AL RAHA BEACH DEVELOPMENT - ABU DHABI",
        src: "https://www.raktherm.com/upload/spic/spic_10al%20raha%20beach1.jpg",
        link: ""
    },
    {
        id: "10",
        title: "ALOFT - AL AIN",
        src: "https://www.raktherm.com/upload/spic/spic_11aloft%20al%20ain1.jpg",
        link: ""
    },
    {
        id: "11",
        title: "BLUE WATERS - DUBAI",
        src: "https://www.raktherm.com/upload/spic/spic_12blue%20waters1.jpg",
        link: ""
    },
    {
        id: "12",
        title: "CAPITAL BAY - DUBAI",
        src: "https://www.raktherm.com/upload/spic/spic_13capital%20bay1.jpg",
        link: ""
    },
    {
        id: "13",
        title: "CENTRIUM TOWER - DUBAI",
        src: "https://www.raktherm.com/upload/spic/spic_14centrium1.jpg",
        link: ""
    },
    {
        id: "14",
        title: "CITY OF LIGHTS - ABU DHABI",
        src: "https://www.raktherm.com/upload/spic/spic_15city%20of%20Lights1.jpg",
        link: ""
    },
    {
        id: "15",
        title: "CITY SCHOOL - AJMAN",
        src: "https://www.raktherm.com/upload/spic/spic_16city%20school1.jpg",
        link: ""
    },
    {
        id: "16",
        title: "FESTIVAL CITY WATERFRONT CENTRE - DUBAI",
        src: "https://www.raktherm.com/upload/spic/spic_17dubai%20festive1.jpg",
        link: ""
    },
    {
        id: "17",
        title: "EXCLUSIVE BAY DAMAC - DUBAI",
        src: "https://www.raktherm.com/upload/spic/spic_18executive1.jpg",
        link: ""
    },
    {
        id: "18",
        title: "GULF OASIS TOWER - SHARJAH",
        src: "https://www.raktherm.com/upload/spic/spic_19gulfoasistower122.jpg",
        link: ""
    },
    {
        id: "19",
        title: "HAMENI TOWER - DUBAI",
        src: "https://www.raktherm.com/upload/spic/spic_20hameni1.jpg",
        link: ""
    },
    {
        id: "20",
        title: "INDIGO TOWER - DUBAI",
        src: "https://www.raktherm.com/upload/spic/spic_21indigo%20tower.jpg",
        link: ""
    },
    {
        id: "21",
        title: "ISLAND CITY - ABU DHABI",
        src: "https://www.raktherm.com/upload/spic/spic_22island%20city.jpg",
        link: ""
    },
    {
        id: "22",
        title: "MANAZEL AL REEF 2 - ABU DHABI",
        src: "https://www.raktherm.com/upload/spic/spic_23manazel.jpg",
        link: ""
    },
    {
        id: "23",
        title: "MIRDIF HOUSING PROJECT - DUBAI",
        src: "https://www.raktherm.com/upload/spic/spic_24mirdif%20project1.jpg",
        link: ""
    },
    {
        id: "24",
        title: "ONE AT PALM JUMEIRAH - DUBAI",
        src: "https://www.raktherm.com/upload/spic/spic_25one%20at%20palm1.jpg",
        link: ""
    },
    {
        id: "25",
        title: "PARIS SARBONNE UNIVERSITY - REEM ISLAND",
        src: "https://www.raktherm.com/upload/spic/spic_26parissarbonne%20uni111.jpg",
        link: ""
    },
    {
        id: "26",
        title: "SKY GARDEN - DUBAI",
        src: "https://www.raktherm.com/upload/spic/spic_27sky%20garden1.jpg",
        link: ""
    },
    {
        id: "27",
        title: "SONDOS TOWER - SHARJAH",
        src: "https://www.raktherm.com/upload/spic/spic_28sandos%20tower1.jpg",
        link: ""
    },
    {
        id: "28",
        title: "THE COURT TOWER - DUBAI",
        src: "https://www.raktherm.com/upload/spic/spic_29the%20court%20tower1.jpg",
        link: ""
    },
    {
        id: "29",
        title: "VICEROY HOTEL CHINA STATE - DUBAI",
        src: "https://www.raktherm.com/upload/spic/spic_30viceroy%20hotel1.jpg",
        link: ""
    },
    {
        id: "30",
        title: "WORKERS VILLAGE MUSSAFAH - ABU DHABI",
        src: "https://www.raktherm.com/upload/spic/spic_31workers%20village1.jpg",
        link: ""
    },
    {
        id: "31",
        title: "YASMEEN TOWER - AJMAN",
        src: "https://www.raktherm.com/upload/spic/spic_32yasmin%20tower1.jpg",
        link: ""
    },
    {
        id: "32",
        title: "ZAHKER PALACE - ABU DHABI",
        src: "https://www.raktherm.com/upload/spic/spic_33zakar%20park1.jpg",
        link: ""
    },
    {
        id: "33",
        title: "KHALIFA UNIVERSITY EXTENSION - ABU DHABI",
        src: "https://www.raktherm.com/upload/spic/spic_34khalifa%20university.jpg",
        link: ""
    },
]