export const CATALOGUES = [
  {
    name: "MULTI-LAYER UPVC CATALOGUE",
    link: "MULTI-LAYER-UPVC-CATALOGUE.pdf",
  },
  {
    name: "PVC ENGLISH BROCHURE",
    link: "PVC2018.pdf",
  },
  {
    name: "PPR & PEX ENGLISH CATALOGUE",
    link: "english-catalogue.pdf",
  },
  {
    name: "PPR FRENCH CATALOGUE",
    link: "french-catalogue.pdf",
  },
  {
    name: "UV Catalogue",
    link: "uv-catalogue.pdf",
  },
  {
    name: "Reinforced Catalogue",
    link: "RAKtherm-Reinforced-Catalogue.pdf",
  },
  {
    name: "PPR & PEX ENGLISH BROCHURE",
    link: "48pages2018.pdf",
  },
  {
    name: "PPR & PEX ARABIC BROCHURE",
    link: "arabic-brochure.pdf",
  },
  {
    name: "Technical Catalogue",
    link: "technical-catalogue.pdf",
  },
  {
    name: "Summary of PPR Pipes and Fittings Standards",
    link: "PPR-PEX-Pipes-Fittings-Standards.pdf",
  },
  {
    name: "Properties & Specification of PPR Raw Material",
    link: "properties-specification-PPR-Raw-Material.pdf",
  },
  {
    name: "Properties & Specification of PEX Material",
    link: "properties-Specification-PEX-Material.pdf",
  },
  {
    name: "RAKtherm Warranty Certificate",
    link: "RAKtherm-Warranty-Certificate.pdf",
  },
];
